import React from "react"
import "../../styles/test_web.css"
import { IoIosBusiness } from "react-icons/io"
import { SiAmazonaws } from "react-icons/si"

export default function App() {

  return (
    <div className="test-section" id="test-de-autoevaluacion">
    <div className="test-section-content">
      <div className="test-title">
        <h2 className="">¿Quieres conocer el nivel de seguridad de tu organización y de los sistemas de información tanto en entornos on premise como en cloud (Amazon Web Services)?</h2>
        <p className="">Realiza nuestros test de autoevaluación para conocer el nivel de seguridad. Sólo tendrás que responder a unas preguntas y en unos minutos tendrás los resultados.</p>
        <a href="/test-autoevaluacion-aws/" className="button-aws">Autoevaluación nivel de seguridad AWS<SiAmazonaws size={50}></SiAmazonaws></a>
        <a href="/test-autoevaluacion/" className="button">Conocer el nivel de seguridad en mi empresa<IoIosBusiness size={50}></IoIosBusiness></a>
      </div>
      <div className="test-img"></div>
    </div>
    </div>
  );
}