import React from "react"

class ActiveCampaignFormIn extends React.Component {
    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://qualoom.activehosted.com/f/embed.php?id=15';
        script.async = true;
        document.body.appendChild(script);
    }
  render() {return (<div><div className='_form_15' /></div>)}
}
export default ActiveCampaignFormIn