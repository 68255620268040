import React from "react"
import "../../styles/index_cta.css"
import img from "../../../static/images/icons/cta_entregas/icon_seguridad.svg"
import ActiveCampaignFormInSeguridad from '../ActiveCampaignFormInSeguridad'

const IndexCTALanding = () => {
  return (
    <div className="cta-landing">
        <div className="cta-content">
          <div className="cta-img"><img src={img} alt="Seguridad en ecommerce"></img></div>
          <div className="cta-text">
            <p className="title">Descubre nuestra última entrega y muchas más sobre Seguridad suscribiéndote a nuestra newsletter </p>
            <p className="entrega">Securización de entornos Cloud</p>
          </div>
        </div>
      <ActiveCampaignFormInSeguridad/>
    </div>
  )
}
export default IndexCTALanding