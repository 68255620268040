import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import SecurityCTA from "../components/index/cta_seguridad"
import Services from "../components/services/servicios_seguridad"
import Location from "../components/services/location_seguridad"
import TestCiberseguridad from "../components/cuestionario/test_ciberseguridad"

const Seguridad = () => {
  const data = useStaticQuery(graphql`
    query imagesSeguridad {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `) 
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Empresa de Servicios de Seguridad Informática | Qualoom</title>
        <meta name="title" content="Empresa de Servicios de Seguridad Informática | Qualoom"/>
        <meta name="description" content="¿Tienes problemas con la Seguridad Informática de tu Empresa? En Qualoom ofrecemos un Servico de Asesoramiento Gratuito '¡Infórmate YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/seguridad-informatica/"/>
        <link rel="canonical" href="https://www.qualoom.es/seguridad-informatica/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/seguridad-informatica/"/>
        <meta property="og:title" content="Empresa de Servicios de Seguridad Informática | Qualoom"/>
        <meta property="og:description" content="¿Tienes problemas con la Seguridad Informática de tu Empresa? En Qualoom ofrecemos un Servico de Asesoramiento Gratuito '¡Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/seguridad-informatica/"/>
        <meta property="twitter:title" content="Empresa de Servicios de Seguridad Informática | Qualoom"/>
        <meta property="twitter:description" content="¿Tienes problemas con la Seguridad Informática de tu Empresa? En Qualoom ofrecemos un Servico de Asesoramiento Gratuito '¡Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/seguridad-informatica/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Seguridad informática", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/", "name": "Seguridad informática"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Servicios de seguridad informática</h1>
              <p>Servicios de ciberseguridad, metodologías DevSecOps y auditorías permitirán a tu organización conocer el nivel actual de seguridad y tomar medidas preventivas sobre las carencias identificadas. Piensa como un hacker y actúa como un director de TI concienciando a tu organización y equipo.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <TestCiberseguridad></TestCiberseguridad>
      <SecurityCTA></SecurityCTA>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Seguridad informática para empresas</h2>
          <hr></hr>
          <p>La seguridad informática, también conocida como ciberseguridad, es el conjunto de  medidas que se toman para proteger los datos, su acceso, su provisión,  el procesamiento y el almacenamiento de los mismos, con el fin de evitar su manipulación o robo por usuarios no autorizados. Su principal objetivo es que, tanto las personas como los equipos que los utilizan, estén protegidos contra amenazas externas.</p><br/>
          <h3>¿Para qué sirve la seguridad informática?</h3>
          <p>Cualquier organización debe prevenir el uso no autorizado de sus sistemas informáticos y mantener las distintas áreas que cubre la seguridad informática:</p>
          <ul>
            <li><b>Confidencialidad.</b> Únicamente los usuarios autorizados deben tener acceso a los recursos, datos e información de tu organización.</li>
            <li><b>Integridad. </b>Los usuarios autorizados deben ser los únicos que puedan modificar los datos cuando esto sea necesario.</li>
            <li><b>Disponibilidad.</b> Todos los datos deben estar disponibles cuando se necesiten.</li>
            <li><b>Autenticación.</b> Los datos que se utilizan deben ser auténticos.</li>
          </ul>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>La importancia de la seguridad informática en las empresas</h2><hr></hr>
          <p>La seguridad en materia de información no es importante en las organizaciones, sino crítica. Organismos, administraciones públicas, usuarios, proveedores y clientes deben estar plenamente informados, concienciados y protegidos de la manera más adecuada contra brechas de seguridad, pérdidas de información y otras alteraciones sobre la principal materia primera de los ecosistemas tecnológicos.</p><br/>
          <h3>¿Necesitas ayuda en seguridad informática?</h3>
          <p>Si desconoces cuál es tu nivel de seguridad en tu infraestructura y activos, te podemos ayudar a realizar un proceso de autoevaluación y diagnóstico como primer paso en el camino del mantenimiento del nivel de seguridad. Nuestra área de ciberseguridad pone a tu disposición el conjunto de herramientas y el equipo de profesionales que te ayudarán a identificar, corregir y mitigar los riesgos materializados en el ámbito de la seguridad informática.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Servicios de Seguridad Informática de Qualoom</h2><hr></hr>
          <h3>¿Qué servicios ofrece Qualoom?</h3><Services></Services><br/>
          <h3>¿Dónde ofrecemos servicios de Qualoom?</h3><Location></Location>
        </div>
      </div>
      <Footer />
      </div>
  )
}

export default Seguridad
